import Service from "@/services/Service";
const baseurl = '/api/citologia/resultado';
import {isEmpty} from "lodash";
export default {
    store(data){
        return Service.post(`${baseurl}/store`, data);
    },
    index(search = '', pagination = {}){
       
        const hasAditionalParams = !!pagination['params'];

        let aditionalParams = {};

        if(hasAditionalParams){
            for(const param in pagination['params']){
                if(
                    pagination['params'][param] !== ''
                    && pagination['params'][param] !== undefined
                    && pagination['params'][param] !== null
                ){
                    aditionalParams[param] = pagination['params'][param]
                }
            }
        }

        const params = {
            ...(search !== '' ? {s:search} : {}),
            ...(isEmpty(pagination['per_page']) ? {per_page : pagination['per_page']} : {}),
            ...(isEmpty(pagination['page']) ? {page : pagination['page']} : {}),
            ...(hasAditionalParams ? {...aditionalParams} : {}),
        };
        console.log(params);
        return Service.get(`${baseurl}`, {
            params : {
                ...params
            }
        });
    },
    print(id) {
        return `${process.env.VUE_APP_BASE_PATH}${baseurl}/print/${id}`
    },
}
