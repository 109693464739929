<template>
  <main>
    <page-header-compact> Lista de Citologias </page-header-compact>
    <div class="container-fluid">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="form-group">
                <label>Profesional</label>
                <v-select-user
                  v-model="pagination.params.id_user"
                ></v-select-user>
              </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-12">
              <div class="form-group">
                <label>Usuario</label>
                <input
                  class="form-control form-control-sm"
                  v-model="pagination.params.usuario"
                />
              </div>
            </div>
            <div class="col-lg-2 col-md-6 col-sm-12">
              <div class="form-group">
                <label>Fecha</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="pagination.params.fecha"
                />
              </div>
            </div>
            <!-- <div class="col-lg-2 col-md-6 col-sm-12">
              <label class="form-label">Estado</label>
              <select
                class="form-control form-control-sm"
                v-model="pagination.params.estado"
              >
                <option value="">--Seleccione--</option>
                <option value="observacion">Observación</option>
                <option value="alta">Alta</option>
                <option value="atencion">Atención</option>
              </select>
            </div> -->
            <div class="col-lg-3 col-md-6 col-sm-12">
              <div class="pt-4">
                <button class="btn btn-info mt-2 btn-sm" @click="index()">
                  <i class="fa fa-filter fa-fw"></i> Filtrar
                </button>
                <button
                  class="btn btn-warning mt-2 btn-sm"
                  @click="resetFilters()"
                >
                  <i class="fa fa-broom fa-fw"></i> Limpiar
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body">
          <data-grid :data="pagination" :search-box="false">
            <template #grid>
              <grid-table class="table table-bordered table-sm">
                <template #head>
                  <grid-cell-header
                    class="bg-light"
                    v-for="(h, i) in headers"
                    :key="`h_${i}${h}`"
                    >{{ h }}</grid-cell-header
                  >
                </template>
                <!--  :class="{'bg-warning-soft':triage.estado==='observacion' || (triage.estado==='atencion' && triage.triage_historia.historia.finalizada==='N')}" -->
                <template #body>
                  <grid-row
                    v-for="(cit, i) in citologias"
                    :key="`${i}_citologias`"
                    :class="mapAppointmentState(cit.resultado).rowClass"
                  >
                    <grid-cell class="text-center font-weight-bolder">
                      {{ cit.id }}
                    </grid-cell>
                    <grid-cell class="text-center">
                      <strong>{{ cit.documento }}</strong>
                      <div>{{ cit.usuario }}</div>
                    </grid-cell>
                    <grid-cell class="text-center font-weight-bolder">{{
                      cit.sede
                    }}</grid-cell>
                    <grid-cell
                      class="text-center font-weight-bolder text-uppercase"
                      >{{ cit.fecha | formatDate }}</grid-cell
                    >
                    <grid-cell
                      class="text-center font-weight-bolder text-uppercase"
                      >{{ cit.user }}</grid-cell
                    >
                    <grid-cell class="justify-content-between">
                        <div :class="mapAppointmentState(cit.resultado).badgeClass" :key="cit.id">
                            <i :class="mapAppointmentState(cit.resultado).badgeIcon"></i>
                            {{ mapAppointmentState(cit.resultado).badgeText }}
                        </div>           
                        <div v-if="cit.resultado==='NO' && _can('resultados.create')">
                                <router-link target="_blank" :to="{
                                    name : 'historias.citologia.resultado.create',
                                    params : {
                                        id : cit.id
                                    }
                            }" class="btn btn-primary btn-icon shadow-sm btn-sm" title="Llenar Resultados" >
                                <i class="fas fa-plus fa-fw"></i>
                            </router-link>
                        </div>
                        <button v-if="cit.resultado==='SI'"
                            title="Imprimir Resultado"
                            class="btn btn-success btn-icon shadow-sm btn-sm"
                            @click="printResults(cit.id)">
                            <i class="fa fa-print fa-fw"></i>
                        </button>
                    </grid-cell>
                  </grid-row>
                </template>
              </grid-table>
            </template>
          </data-grid>
        </div>
      </div>
    </div>
  </main>
</template>
<script>
import PageHeaderCompact from "../../../components/layouts/content/page-header-compact";
import DataGrid from "../../../components/common/utilities/DataGrid/DataGrid";
import GridTable from "../../../components/common/utilities/DataGrid/GridTable";
import GridCellHeader from "../../../components/common/utilities/DataGrid/GridCellHeader";
import paginateMixin from "../../../mixins/PaginateMixin";
import GridRow from "../../../components/common/utilities/DataGrid/GridRow";
import GridCell from "../../../components/common/utilities/DataGrid/GridCell";
import citologiaService from "../../../services/citologiaService";
import VSelectUser from "../../../components/common/vSelectUser";
import typeAppService from "../../../services/typeAppService";

export default {
  name: "citologia.vue",
  components: {
    VSelectUser,
    GridCell,
    GridRow,
    GridCellHeader,
    GridTable,
    DataGrid,
    PageHeaderCompact,
  },
  mixins: [paginateMixin(citologiaService)],
  async created() {
    this.index();
    const { data } = await typeAppService.index();
    this.hcAppLink = data.find((app) => app.name === "HISTORIA").domain;
  },
  data() {
    return {
      headers: ["#", "Usuario", "Sede", "Fecha", "Profesional", "Resultados "],
      pagination: {
        per_page: 5,
        params: {
          id_user: "",
          fecha: "",
          usuario: "",
          estado: "",
        },
      },
      hcAppLink: "",
    };
  },
  methods: {
    printResults(id){
            window.open(citologiaService.print(id));
        },
    resetFilters() {
      this.pagination.params.id_user = "";
      this.pagination.params.usuario = "";
      this.pagination.params.fecha = "";
      this.pagination.params.estado = "";
      this.index();
    },
    mapAppointmentState(resultado) {
      if (resultado === "NO") {
        return {
          rowClass: "bg-danger-soft",
          badgeClass: "badge badge-marketing badge-danger",
          badgeIcon: "fa fa-times-circle fa-fw",
          badgeText: "NO",
        };
      } else if (resultado === "SI") {
        return {
          rowClass: "bg-success-soft",
          badgeClass: "badge badge-marketing badge-success",
          badgeIcon: "fa fa-check fa-fw",
          badgeText: "SI",
        };
      } 
      [resultado];
    },
  },
  computed: {
    citologias: function () {
      return this.pagination.data.map((citologa) => {
        return {
          id: citologa.id,
          id_usuario: citologa.historia.usuario.id,
          documento: citologa.historia.usuario.documento,
          usuario: `${citologa.historia.usuario.nombre_1} ${citologa.historia.usuario.nombre_2} ${citologa.historia.usuario.apellido_1} ${citologa.historia.usuario.apellido_2}`,
          sede: `${citologa.historia.sede.NOMBRE_SEDE}`,
          user: citologa.historia.user.name,
          id_user: citologa.historia.id_user,
          fecha: citologa.fecha_lectura,
          resultado: citologa.resultado?'SI':'NO'
        };
      });
    },
    user() {
      return this.$store.state.user;
    },
  },
};
</script>
